.form {
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #424242;
}

.installments {
  margin-bottom: 16px;
}

.installments :first-child div {
  background-color: #ffffff;
}

.inputMargin {
  margin-bottom: 16px;
}

.inputMargin :first-child div {
  background-color: #ffffff;
}

.inputMarginCard {
  position: relative;
  margin-bottom: 16px;
}

.containerMonths {
  display: flex;
  max-width: 100%;
}

.containerMonths input {
  width: 100%;
  border: 1px solid #bdbdbd;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #212121;
}

.containerMonths input:nth-child(1) {
  border-radius: 0 0 0 8px;
}

.containerMonths input:nth-child(2) {
  border-radius: 0 0 8px 0;
}

.inputPrincipal {
  margin-top: 4px;
  display: block;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #bdbdbd;
  padding: 8px 16px;
  min-width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #212121;
}

.inputPrincipal::placeholder,
.containerMonths input::placeholder {
  color: #bdbdbd;
}

.inputPrincipal:hover,
.containerMonths input:hover {
  border-color: #212121;
}

.inputPrincipal:focus,
.containerMonths input:focus {
  border-color: #212121;
}

.alert {
  margin: 16px 0;
  max-width: 100%;
}

.iconCardHolder {
  position: absolute;
  right: 19px;
  top: 34px;
}

.iconCardCvc {
  position: absolute;
  right: 19px;
  top: 74px;
}

.securedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secured {
  display: flex;
}

.securedText {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  letter-spacing: 0.004em;
  margin-left: 11px;
}

.banner {
  margin-bottom: 16px;
}

.banner div {
  width: 100%;
}

.button {
  background-color: #ffffff;
  padding: 16px 16px 0 16px;
  margin: 20px -16px -16px -16px;
}

.textPreventAddress {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #757575;
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 1024px) {
  .button {
    padding: 16px 0 0 0;
    margin: 20px -16px -16px -16px;
  }
}

.disclaimer {
  margin-bottom: 16px;
}
