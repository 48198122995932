.container {
  padding: 24px 16px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.title {
  color: #212121;
  font-family: 'Poppins Medium', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.0015em;
  line-height: 24px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.circle {
  margin-right: 12px;
  background-color: #5298f5;
  width: 20px;
  height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100%;
}

.circle p {
  font-family: 'Poppins Normal', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0015em;
}

@media (min-width: 1024px) {
  .container {
    padding: 0;
    border: none;
  }
}
