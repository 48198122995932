.container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-top: 64px;
}

@media (min-width: 1024px) {
  .container {
    flex-direction: row;
  }
}
