.container {
  width: 100%;
  padding-top: 64px;
}

.textsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  padding-top: 20px;
  padding-bottom: 16px;
}

.infoContainer {
  margin-top: -92px;
  padding: 16px;
}

.infoSubContainer {
  max-width: 90%;
  background-color: white;
  border-radius: 16px;
  -webkit-box-shadow: 0 6px 8px rgba(0,0,0,0.16);
  box-shadow: 0 6px 8px rgba(0,0,0,0.16);
}

.line {
  background-color: #E0E0E0;
  height: 1px;
  width: 100%;
}

.promoContainer {
  background-color: #EEEEEE;
  border-radius: 4px;
  margin-right: 10px;
}

.whatsapp {
  margin-left: 10px;
}

.infoContainerWithButton {
  margin-top: -82px !important;
}

.copyContainer {
  border-radius: 8px;
  margin-bottom:15px
}

.copyContainer img {
  cursor: pointer;
}

.bannerExpiration {
  background-color: transparent;
  max-width: 100%;
}

.barcodeContainer {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundBarcode {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zoomBarcode {
  position: absolute;
  overflow: hidden;
  background-color: #FFFFFF;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.dotSteps {
  background-color: #05A357;
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-radius: 10px;
  margin-right: 16px;
}

.barcodeOpenContainer {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #212121;
  overflow-y: hidden;
  overflow-x: hidden;
}

.headerBarcode {
  border-bottom: 1px solid #E0E0E0;
}

.bodyBarcode {
  height: 90%;
}

.bodyBarcode img {
  max-width: 400px;
}

.buttonsBarcode {
  cursor: pointer;
  margin-left: 30px;
}

.customTooltip {
  font-family: "Inter Regular", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 8px 10px !important;
}

.customTooltip::after {
  left: 88% !important;
}

.customTooltipAddress {
  max-width: 270px;
  font-family: "Inter Regular", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 8px 10px !important;
}

.customTooltipAddress::after {
  left: 89% !important;
}

.customTooltipCoupon {
  max-width: 240px;
  font-family: "Inter Regular", sans-serif !important;
  font-size: 14px !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 8px 10px !important;
  text-align: center;
}
.Check_Oxxo_DownloadPdf{
  font-family: "Inter Regular", sans-serif;
  font-size: 16px;
  color: #212121;
  text-decoration: underline;
  cursor: pointer;
}

.thankYouContainer {
  margin-bottom: 0px;
}

@media (max-width: 360px) {
  .customTooltipCoupon {
    max-width: 200px;
  }
  .thankYouContainer {
    margin-bottom: 0px;
  }
}

@media (min-width: 769px) {
  .thankYouContainer {
    height: 400px;
    margin-bottom: 30px;
  }
  .infoSubContainer {
    max-width: 704px;
  }
  .infoContainer {
    margin-top: -136px;
  }
  .buttonsContainer {
    max-width: 296px;
  }
  .dotSteps {
    min-height: 24px;
    min-width: 24px;
    max-height: 24px;
    max-width: 24px;
    border-radius: 12px;
  }
  .zoomBarcode {
    padding: 16px;
  }
}
