.container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 99;
}

.modal {
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 525px;
  overflow-y: scroll;
  font-family: 'Inter Regular', sans-serif;
  text-align: center;
  padding: 32px;
}

.title {
  font-family: 'Poppins Medium', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 24px 0;
}

.description {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #616161;
  margin-bottom: 24px;
}

.button {
  margin: 0 68px 24px 68px;
}

.discard {
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  letter-spacing: 0.0125em;
  color: #000000;
}

@media (min-width: 1024px) {
  .container {
    position: fixed;
    align-items: center;
  }

  .modal {
    border-radius: 16px;
    min-width: 480px;
    max-width: 480px;
    overflow: hidden;
    height: auto;
  }
}
