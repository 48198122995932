.imgProduct {
  margin-right: 8px;
}

.textDecoration {
  text-decoration: line-through;
}

.productSummaryImg{
  border-radius: 3px;
}
