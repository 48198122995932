.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  min-height: 56px;
  margin-top: 16px;
  padding: 16px;
}

.containerError {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  min-height: 56px;
  margin-top: 16px;
  padding: 16px;
  border-style: solid;
  border-color: #e11900;
  border-width: 2px;
}

.text {
  color: #757575;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.containerAddress {
  display: flex;
  flex-direction: column;
}

.titleAddress {
  font-family: 'Inter Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.0125em;
  margin-bottom: 8px;
  text-align: left;
}

.Add_address {
  color: #e61e4d;
  font-family: 'Inter Bold', sans-serif;
  font-weight: bold;
  font-size: 14px;
}

@media (min-width: 1024px) {
  .container {
    width: 518px;
    cursor: pointer;
  }

  .containerError {
    width: 518px;
    cursor: pointer;
  }

  .containerAddress {
    display: flex;
    flex-direction: column;
  }

  .titleAddress {
    font-family: 'Inter Bold', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    align-self: flex-start;
    letter-spacing: 0.0125em;
    margin-bottom: 8px;
  }

  .text {
    font-size: 16px;
    letter-spacing: 0.005em;
    line-height: 24px;
  }
  .Add_address {
    font-size: 16px;
  }
}
