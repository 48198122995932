.container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 99;
}

.hiddenButton {
  width: 100%;
  height: 100%;
  cursor: inherit;
  position: absolute;
}

.modal {
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 535px;
  overflow-y: scroll;
  font-family: 'Inter Regular', sans-serif;
  z-index: 1000;
}

.header {
  position: relative;
  padding: 16px;
  border-bottom-color: #e0e0e0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.closeButton {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.title {
  color: #212121;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  letter-spacing: 0.0025em;
  line-height: 20px;
}

.Dropdown div div {
  position: relative !important;
}

.form {
  padding: 16px;
}

.containerNum {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.containerNum div {
  width: 100%;
}

.containerNumMargin {
  margin-right: 10px;
}

.inputMargin {
  margin-bottom: 16px;
}

.alert {
  margin: 16px 0;
  max-width: 100%;
}

@media (min-width: 1024px) {
  .container {
    position: fixed;
    align-items: center;
  }

  .modal {
    border-radius: 16px;
    min-width: 480px;
    max-width: 480px;
    overflow: hidden;
    height: auto;
  }
}
