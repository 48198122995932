.container {
  padding: 24px 0;
}

.title {
  font-family: 'Poppins Medium', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-left: 16px;
}

.title2 {
  font-family: 'Poppins Medium', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-left: 16px;
  margin-bottom: 16px;
}

.buttonContainer {
  margin: 16px 16px 24px 16px;
  display: flex;
  max-height: 44px;
  width: 100%;
}

.containerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px 16px;
}

.buttonPrimary {
  padding: 9px 29px;
  background-color: #e61e4d;
  border-radius: 8px 0px 0px 8px;
  color: #ffffff;
  font-family: 'Inter Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  width: 100%;
}

.buttonPrimaryChange {
  border: 1px solid #bdbdbd;
  padding: 9px 29px;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  border-radius: 8px 0px 0px 8px;
  width: 100%;
}

.buttonSecondary {
  border: 1px solid #bdbdbd;
  padding: 9px 29px;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  border-radius: 0px 8px 8px 0px;
  width: 100%;
}

.buttonSecondaryChange {
  padding: 9px 29px;
  background-color: #e61e4d;
  border-radius: 0px 8px 8px 0px;
  color: #ffffff;
  font-family: 'Inter Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  width: 100%;
}

.change {
  font-family: 'Inter Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #e61e4d;
  letter-spacing: 0.0125em;
}

.containerPayment {
  margin-top: 24px;
  padding: 0 16px;
}

.containerPolicy {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.policy {
  font-family: 'Inter Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .title {
    margin-left: 0;
  }

  .title2 {
    margin-left: 0;
  }

  .buttonContainer {
    margin: 16px 0 24px 0;
  }

  .buttonPrimary {
    padding: 9px 29px;
  }

  .buttonPrimaryChange {
    padding: 9px 29px;
  }

  .buttonSecondary {
    padding: 9px 29px;
  }

  .buttonSecondaryChange {
    padding: 9px 29px;
  }

  .containerActions {
    padding: 0;
    margin-bottom: 16px;
  }

  .containerPayment {
    margin-top: 24px;
    padding: 0;
  }
}
